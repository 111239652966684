export const modalNames = {
    ORDER_DETAILS_POPUP: 'OrderDetailsPopup',
    LEFT_NAV_POPUP: 'LeftNavPopup',
    LOCATION_POPUP: 'LocationPopup',
    DELETE_LOCATION_POPUP: 'DeleteLocation',
    PURCHASE_FUEL_POPUP: 'PurshaseFuelPopUp',
    FUEL_PURCHASE_CONFIRMATION_POPUP: 'FuelPurchaseConfirmation',
    PRIVACY_POLICY_POPUP: 'privacy-policy',
    TERM_OF_SERVICE_POPUP: 'terms-of-service',
    VIEW_REPORTING_POPUP: 'ViewReporting',
    VIEW_REPORTING_POPUP_MOBILE: 'ViewReportingMobile',
    VIEW_MANAGE_POPUP: 'ViewManage',
    VIEW_MANAGE_POPUP_MOBILE: 'ViewManageMobile',
    CONFIRM_AUT_FILL_POPUP: 'ConfirmAutoFill'
};
